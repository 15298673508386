import { ClockIcon, MailIcon } from "../../../../assets/icons";
import { PhoneIcon } from "../../../../assets/icons/Phone";
import style from "./Popup.module.scss";

type PopupType = {
  top?: number;
  left?: number;
  isOpen?: boolean;
  closePopup?: () => void;
};

const SupportPopup = (props: PopupType) => {
  return (
    <div className={style.popup}>
      <div className={style.header}>
        <h3>Техподдержка</h3>
      </div>
      <div className={style.content}>
        <span className={style.mediaItem}>
          <div className={style.iconWrapper}>
            <PhoneIcon color="green" />
          </div>
          <div className={style.title}>Телефон</div>
          <div className={style.value}>
            <a href="tel:88003337840">8 800 333-78-40</a>
          </div>
        </span>
        <span className={style.mediaItem}>
          <div className={style.iconWrapper}>
            <MailIcon />
          </div>
          <div className={style.title}>Эл. почта</div>
          <div className={style.value}>
            <a href="mailto:sc.support@icl-services.com">
              sc.support@icl-services.com
            </a>
          </div>
        </span>
        <span className={style.mediaItem}>
          <div className={style.iconWrapper}>
            <ClockIcon />
          </div>
          <div className={style.title}>График работы</div>
          <div className={style.value}>пн-пт 9-18</div>
        </span>
      </div>
    </div>
  );
};

export default SupportPopup;
