import { Input, InputProps, Tooltip } from "antd";
import MaskedInput from "./MaskedInput";
import style from "./AntInput.module.scss";
import FloatLabel from "../FloatLabel";
import { PasswordProps } from "antd/es/input";
import { EyeIcon } from "../../assets/icons/Eye";
import { EyeOffIcon } from "../../assets/icons/EyeOff";
import { HelpCircleIcon } from "../../assets/icons/HelpCircle";
import { MaskedInputProps } from "./MaskedInput/lib/MaskedInput";
import React from "react";
import cn from "classnames";

const WrapperFloatLabel = (
  props: InputProps | PasswordProps | MaskedInputProps
) => {
  const { prefix, placeholder, value } = props;

  return (
    <FloatLabel
      fieldIsRequired={props["aria-required"]}
      label={placeholder || ""}
      value={!!value}
      hasPrefix={!!prefix}
      fieldIsInvalid={props["aria-invalid"]}
    >
      <>{props.children}</>
    </FloatLabel>
  );
};

const AntInput = (props: InputProps) => {
  const { onFocus, value } = props;

  return (
    <WrapperFloatLabel {...props}>
      <Input
        {...props}
        placeholder={undefined}
        className={cn(style.input, {
          [style.isFilled]: !!value,
        })}
        onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => {
          if (onFocus) {
            onFocus(event);
          }
        }}
      />
    </WrapperFloatLabel>
  );
};

const Password = (props: PasswordProps) => {
  const { value, onFocus } = props;

  return (
    <WrapperFloatLabel {...props}>
      <Input.Password
        {...props}
        placeholder={undefined}
        className={cn(style.input, {
          [style.isFilled]: !!value,
        })}
        onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => {
          if (onFocus) {
            onFocus(event);
          }
        }}
        iconRender={(visible) =>
          value ? (
            visible ? (
              <span>
                <EyeIcon />
              </span>
            ) : (
              <span>
                <EyeOffIcon />
              </span>
            )
          ) : props["aria-invalid"] ? undefined : (
            <Tooltip
              title="Пароль должен состоять не менее, чем из 8 символов, среди которых должны быть минимум 1 заглавная латинская буква и 1 арабская цифра"
              overlayClassName={style.tooltip}
            >
              <HelpCircleIcon />
            </Tooltip>
          )
        }
      />
    </WrapperFloatLabel>
  );
};

const Mask = (props: MaskedInputProps) => {
  const { onFocus, value } = props;

  return (
    <WrapperFloatLabel {...props}>
      <MaskedInput
        {...props}
        placeholder={undefined}
        maskOptions={{
          lazy: true,
        }}
        className={cn(style.input, {
          [style.isFilled]: !!value,
        })}
        onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => {
          if (onFocus) {
            onFocus(event);
          }
        }}
      />
    </WrapperFloatLabel>
  );
};

AntInput.Password = Password;
AntInput.Mask = Mask;

export default AntInput;
