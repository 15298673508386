import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { TStatus } from "../types";

interface IInitialState {
  preloaderStatus: boolean;
  errors: string[];
  status: TStatus;
}

const initialState: IInitialState = {
  preloaderStatus: false,
  errors: [],
  status: "idle",
};

export const slice = createSlice({
  name: "appSettings",
  initialState,

  reducers: {
    setPreloaderStatus: (state, action: PayloadAction<boolean>) => {
      state.preloaderStatus = action.payload;
    },
    setAppError: (state, action: PayloadAction<string>) => {
      const newErrors = state.errors.slice();
      newErrors.push(action.payload);
      state.errors = newErrors;
    },
  },
});

export const { setPreloaderStatus, setAppError } = slice.actions;

export const appPreloaderStatusState = (state: RootState) =>
  state.appSettings.preloaderStatus;

const appSettingsReducer = slice.reducer;
export default appSettingsReducer;
