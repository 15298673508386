import { initializeApp } from "firebase/app";
import {
  getToken,
  getMessaging,
  onMessage,
  deleteToken,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDz1deZDwQPYjZgCdCMDi2tagHG3dfdUOQ",
  authDomain: "pretripmobileapp.firebaseapp.com",
  projectId: "pretripmobileapp",
  storageBucket: "pretripmobileapp.appspot.com",
  messagingSenderId: "246012165951",
  appId: "1:246012165951:web:907367700c49dc44aa9d8a",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const deleteCurrentToken = () => {
  deleteToken(messaging);
};

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;

        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) => {
      return getToken(messaging, {
        vapidKey:
          "BPMgTlh4tPV_zPq1NjJLu99TqBTQ6uz2rH9lpbxrcn4UofGPUvIS_Yp-y0ieazqR3l8zq3P8cbh_UPfP-rAwiGk",
        serviceWorkerRegistration,
      });
    })
    .catch((err) => {
      console.error("Error while installing Service Worker", err);
      throw err;
    });

export const onForegroundMessage = (func: any) => onMessage(messaging, func);
