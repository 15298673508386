import style from "../../variables.module.scss";

export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  let newColor = "#67D394";
  const color = props.color;
  if (color) {
    if (color === "gray") {
      newColor = style.grayColor;
    }
  }
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_192_10116)">
        <path
          d="M6.24984 10.0003L8.74984 12.5003L13.7498 7.50033M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003Z"
          stroke={newColor}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_10116">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
