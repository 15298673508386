export const UsersRightIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8334 16.5L18.3334 14M18.3334 14L15.8334 11.5M18.3334 14H13.3334M12.9167 1.7423C14.1383 2.23679 15 3.43443 15 4.83333C15 6.23224 14.1383 7.42988 12.9167 7.92437M10 11.5H6.66669C5.11355 11.5 4.33698 11.5 3.72441 11.7537C2.90765 12.092 2.25874 12.741 1.92042 13.5577C1.66669 14.1703 1.66669 14.9469 1.66669 16.5M11.25 4.83333C11.25 6.67428 9.75764 8.16667 7.91669 8.16667C6.07574 8.16667 4.58335 6.67428 4.58335 4.83333C4.58335 2.99238 6.07574 1.5 7.91669 1.5C9.75764 1.5 11.25 2.99238 11.25 4.83333Z"
      stroke="#85879B"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
