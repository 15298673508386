import { Checkbox, CheckboxProps } from "antd";
import style from "./AntCheckbox.module.scss";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import cn from "classnames";

const AntCheckbox = (props: CheckboxProps) => {
  const className = cn(style.checkBox, props.className);

  return (
    <Checkbox {...props} className={className}>
      {props.children}
    </Checkbox>
  );
};

AntCheckbox.Group = (props: CheckboxGroupProps) => {
  return <Checkbox.Group {...props}>{props.children}</Checkbox.Group>;
};

export default AntCheckbox;
