import { Popconfirm, PopconfirmProps } from "antd";
import style from "./AntPopConfirm.module.scss";
import styleAntButtons from "../AntButton/AntButton.module.scss";
import { AlertCircleIcon } from "../../assets/icons/AlertCircle";
import cn from "classnames";

const AntPopConfirm = (props: PopconfirmProps) => {
  return (
    <Popconfirm
      {...props}
      overlayClassName={style.popConfirm}
      icon={<AlertCircleIcon />}
      okButtonProps={{
        className: cn(styleAntButtons.modalBtn, styleAntButtons.typeUpper),
      }}
      cancelButtonProps={{
        className: cn(styleAntButtons.modalBtn, styleAntButtons.typeUpper),
      }}
    >
      {props.children}
    </Popconfirm>
  );
};

export default AntPopConfirm;
