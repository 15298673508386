import { useState } from "react";

import style from "./FloatLabel.module.scss";
import cn from "classnames";

interface IFloatLabel {
  children: JSX.Element;
  label: string | React.ReactNode;
  value: boolean;
  hasPrefix?: boolean;
  fieldIsRequired: boolean | "true" | "false" | undefined;
  fieldIsInvalid:
    | boolean
    | "false"
    | "true"
    | "grammar"
    | "spelling"
    | undefined;
}

const FloatLabel = (props: IFloatLabel) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, hasPrefix, fieldIsInvalid, fieldIsRequired } =
    props;

  let labelIsRequired = false;
  if (fieldIsRequired) {
    if (typeof fieldIsRequired === "string" && fieldIsRequired === "true") {
      labelIsRequired = true;
    }
  }

  return (
    <div
      className={cn(style.wrapperLabel)}
      onBlur={() => {
        setFocus(false);
      }}
      onFocus={() => {
        setFocus(true);
      }}
    >
      {children}
      <label
        className={cn(style.label, {
          [style.labelFloat]: focus || value,
          [style.hasPrefix]: hasPrefix,
          [style.isFilled]: value,
          [style.fieldIsInvalid]: fieldIsInvalid,
        })}
      >
        {labelIsRequired ? label + "*" : label}
      </label>
    </div>
  );
};

export default FloatLabel;
