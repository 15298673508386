// Функция для определения правильного окончания слова "уведомление"
export const getNotificationText = (count: number) => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return `${count} новое уведомление`;
  } else if (
    [2, 3, 4].includes(count % 10) &&
    ![12, 13, 14].includes(count % 100)
  ) {
    return `${count} новых уведомления`;
  } else {
    return `${count} новых уведомлений`;
  }
};
