export const HelpCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1166_2539)">
      <path
        d="M7.57496 7.49984C7.77088 6.94289 8.15759 6.47326 8.66659 6.17411C9.17559 5.87497 9.77404 5.76562 10.3559 5.86543C10.9378 5.96524 11.4656 6.26777 11.8459 6.71944C12.2261 7.17111 12.4342 7.74277 12.4333 8.33317C12.4333 9.99984 9.93329 10.8332 9.93329 10.8332M9.99996 14.1665H10.0083M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z"
        stroke="#85879B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1166_2539">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
