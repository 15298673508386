import { Form, FormItemProps } from "antd";
import style from "./AntFormItem.module.scss";
import cn  from 'classnames';

const AntFormItem = (props: FormItemProps) => {
  return (
    <Form.Item {...props} className={cn(style.formItem, props.className)}>
      {props.children}
    </Form.Item>
  );
};

export default AntFormItem;
