import { ArgsProps } from "antd/lib/message";
import style from "./AntMessageConfig.module.scss";
import { AlertCircleIcon } from "../../assets/icons/AlertCircle";
import { CheckCircleIcon } from "../../assets/icons/CheckCircle";

const createConfig = ({
  type,
  content,
}: {
  type: "success" | "error" | "warning";
  content: string;
}): ArgsProps => {
  let icon = (
    <span
      role="img"
      aria-label="check-circle"
      className="anticon anticon-check-circle"
    >
      <CheckCircleIcon />
    </span>
  );

  if (type === "error") {
    icon = (
      <span
        role="img"
        aria-label="close-circle"
        className="anticon anticon-close-circle"
      >
        <AlertCircleIcon />
      </span>
    );
  }
  return {
    type,
    content,
    className: style.message,
    duration: 7,
    icon,
  };
};

export default createConfig;
