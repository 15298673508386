export enum ExamTypeEnum {
  Предрейсовый,
  Предсменный,
  Послерейсовый,
  Послесменный,
}

export enum GenderTypeEnum {
  Мужской,
  Женский,
}

export enum EnumRoles {
  Обследуемый = "Обследуемый",
  Руководитель = "Руководитель",
  Врач = "Врач",
  Админ = "Админ",
  Механик = "Механик",
}

export enum EnumEducationType {
  "Специалитет",
  "Интернатура",
  "Ординатура",
  "Аспирантура",
  "Повышение квалификации",
  "Бакалавриат",
  "Магистратура",
}

export interface IDecoded {
  UserId: number;
  PersonId: number | null;
  Role: EnumRoles | EnumRoles[];
  exp: number;
}

export type TypeRole = EnumRoles | EnumRoles[];

export type TypeStringNullUndefined = string | null | undefined;
export type TypeNumberNullUndefined = number | null | undefined;

export interface INotification {
  id: number;
  title: string;
  description: string;
  time: string;
  examId: number;
  status: "new" | "viewed";
}

export type TypeNewExam = {
  Title: string;
  Body: string;
  ExamId: number;
};
