import { Button, ButtonProps } from "antd";
import style from "./AntButtonGreen.module.scss";
import cn from "classnames";

const AntButtonGreen = (props: ButtonProps) => {
  const classNames = cn(style.button, props.className, {
    [style.link]: props.type === "link",
  });
  return (
    <Button size="large" shape="round" {...props} className={classNames}>
      {props.children}
    </Button>
  );
};
export default AntButtonGreen;
