import style from "./Login.module.scss";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/AntPreloader";
import { Form, message } from "antd";
import AntFormItem from "../../components/AntFormItem";
import AntInput from "../../components/AntInput";
import { UsersRightIcon } from "../../assets/icons/UsersRight";
import { AlertCircleIcon } from "../../assets/icons/AlertCircle";
import { LockIcon } from "../../assets/icons/Lock";
import {
  authKeepState,
  authStatusState,
  setGuestStatus,
  setKeep,
} from "../../storeSlices/login";
import {
  appPreloaderStatusState,
  setPreloaderStatus,
} from "../../storeSlices/appSettings";
import createConfig from "../../components/AntMessageConfig";
import AntButtonGreen from "../../components/AntButtonGreen";
import {
  useLazyGetApiDoctorsGetByPersonIdByPersonIdQuery,
  useLazyGetApiPersonsByIdQuery,
  usePostApiAuthenticationLoginMutation,
} from "../../app/apiRTK";
import { IclWorkShiftIcon } from "../../assets/icons/IclWorkShift";
import { CheckCircleIcon } from "../../assets/icons/CheckCircle";
import AntCheckbox from "../../components/AntCheckbox";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { jwtDecode } from "jwt-decode";
import { EnumRoles, IDecoded } from "../../app/types";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(authStatusState);
  const authKeep = useAppSelector(authKeepState);
  const preloaderStatus = useAppSelector(appPreloaderStatusState);

  const [errorField, setErrorField] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const ref = useRef<true | null>(null);

  // useGet
  const [login] = usePostApiAuthenticationLoginMutation();

  // useLazy
  const [getAuthPerson] = useLazyGetApiPersonsByIdQuery();
  const [getAuthDoctor] = useLazyGetApiDoctorsGetByPersonIdByPersonIdQuery();

  /*   Когда пользователь переходит сам на страницу или его перенаправляют сюда, 
  проверяем его статус и в случае, если он авторизован, 
  перенаправляем на главную страницу или сохраненный путь */
  useEffect(() => {
    if (authStatus === "failed") {
      setErrorField(true);
    } else if (authStatus === "authorized") {
      const savedPath = sessionStorage.getItem("firstEntry");
      if (savedPath) {
        navigate(savedPath);
      } else {
        navigate("/");
      }
    } else if (authStatus === "loading") {
      // nothing
    } else if (authStatus === "failedRefresh") {
      // nothing
    } else {
      setErrorField(false);
    }
  }, [authStatus, navigate]);

  useEffect(() => {
    if (ref.current) {
      form.validateFields();
    } else {
      ref.current = true;
    }
  }, [errorField, form]);

  const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    // Хак для отключения автокомплита
    (event.target as HTMLInputElement).removeAttribute("readonly");

    if (authStatus === "failed") {
      dispatch(setGuestStatus());
    }
  };

  const onCheckbox = (e: CheckboxChangeEvent) => {
    dispatch(setKeep(e.target.checked));
  };

  /* Пытаемся авторизоваться на сервере. 
  Если учетные данные верны, меняем в редюсере статус на authorized */
  const onSubmit = async (values: any) => {
    dispatch(setPreloaderStatus(true));

    login({
      authModel: {
        login: values.login,
        password: values.password,
      },
    })
      .unwrap()
      .then(({ access }) => {
        if (access) {
          const accessDecoded: IDecoded = jwtDecode(access);
          const personId = accessDecoded.PersonId;
          const roles = accessDecoded.Role;
          if (personId) {
            getAuthPerson({ id: personId });

            if (roles.includes(EnumRoles.Врач)) {
              getAuthDoctor({ personId });
            }
          }
        }
      })
      .catch((response) => {
        if (response.status === 400 || response.status === 401) {
          return;
        }

        messageApi.open(
          createConfig({
            type: "error",
            content: "Ошибка сервера при попытке входа",
          })
        );
      });

    dispatch(setPreloaderStatus(false));
  };

  return (
    <>
      {contextHolder}
      <Preloader spinning={preloaderStatus} size="large">
        <div className={style.wrapper}>
          <div className={style.picture}></div>
          <div className={style.formWrapper}>
            <IclWorkShiftIcon />
            <div className={style.form}>
              <h2 className={style.header}>Вход в личный кабинет</h2>

              <Form
                feedbackIcons={() => ({
                  error: <AlertCircleIcon />,
                  success: <CheckCircleIcon />,
                })}
                onFinish={onSubmit}
                form={form}
              >
                <AntFormItem
                  hasFeedback
                  name="login"
                  rules={[
                    {
                      required: true,
                      message: "Обязательно к заполнению",
                    },
                    () => ({
                      validator() {
                        if (!errorField) {
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                >
                  <AntInput
                    id="login"
                    prefix={<UsersRightIcon />}
                    placeholder="Логин"
                    onFocus={onFocus}
                    readOnly
                  />
                </AntFormItem>
                <AntFormItem
                  hasFeedback
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Обязательно к заполнению",
                    },
                    () => ({
                      validator() {
                        if (!errorField) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Неправильно введен логин или пароль"
                        );
                      },
                    }),
                  ]}
                >
                  <AntInput.Password
                    id="password"
                    prefix={<LockIcon />}
                    placeholder="Пароль"
                    onFocus={onFocus}
                    readOnly
                  />
                </AntFormItem>

                <AntCheckbox onChange={onCheckbox} checked={authKeep}>
                  Запомнить меня
                </AntCheckbox>

                <AntButtonGreen
                  type="primary"
                  htmlType="submit"
                  className={style.antFormButton}
                >
                  Войти
                </AntButtonGreen>
              </Form>
            </div>
          </div>
        </div>
      </Preloader>
    </>
  );
};

export default Login;
